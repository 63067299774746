//add product to cart

export class OrderHelper {
  constructor(http, store, lang = 2) {
    // this.context = context;
    this.http = http;
    this.store = store;
    if (lang === "fr") {
      this.lang = 1;
    } else if (lang === "de") {
      this.lang = 3;
    } else {
      this.lang = 2;
    }
  }

  async getList() {
    try {
      const res = await this.http.API_AUTH.$post("orders/list");
      if (Array.isArray(res)) {
        this.store.commit("user/orders/initialiseStore", res);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async getOrderById(idOrder) {
    try {
      var FormData = require("form-data");
      var formData = new FormData();
      formData.append("IdOrder", idOrder);
      const res = await this.http.API_AUTH.post("order/detail", formData);
      if (res.status === 200) {
        return res.data;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async getListReturns({ languageIsoCode }) {
    try {
      const response = await this.http.API_AUTH.get(
        `order/return/list?LanguageIsoCode=${languageIsoCode}`
      );
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  async addReturnProducts({ idOrder, productsObject, languageIsoCode }) {
    try {
      var FormData = require("form-data");
      var formData = new FormData();
      formData.append("IdOrder", idOrder);
      formData.append("Products", JSON.stringify(productsObject));
      formData.append("LanguageIsoCode", languageIsoCode);
      const res = await this.http.API_AUTH.post(
        "order/return/create",
        formData
      );
      if (res.status === 200) {
        return res;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async getReturnDetail({ idOrderReturn, languageIsoCode }) {
    try {
      const response = await this.http.API_AUTH.get(
        `order/return/detail?LanguageIsoCode=${languageIsoCode}&IdOrderReturn=${idOrderReturn}`
      );
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  async applyPromoCode({ code, idCart }) {
    try {
      const res = await this.http.API.$get(
        `promocode/customer/apply?PromoCode=${code}&IdCart=${idCart}`
      );
      if (res) {
        return res;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async applyPromoCode2({
    code = null,
    idCart,
    loyaltyRewardStateLabel = null,
  }) {
    var FormData = require("form-data");
    var formData = new FormData();
    if (code) {
      formData.append("PromoCode", code);
    }
    if (loyaltyRewardStateLabel) {
      formData.append("LoyaltyRewardStateLabel", loyaltyRewardStateLabel);
    }
    formData.append("IdCart", idCart);

    const response = await this.http.API_AUTH.post(
      `cart/promocode/add`,
      formData
    );
    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw new Error(`Unexpected status code: ${response.status}`);
    }
  }

  async verifyPromoCode() {
    try {
      const res = await this.http.API_AUTH.$post(`promocode/checkValidity`);
      if (res) {
        return res;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async removeCodePromo({ idCart, idPromoCode }) {
    const qs = require("qs");
    let dataQS = qs.stringify({
      IdCart: idCart,
      IdPromoCode: idPromoCode,
    });
    const response = await this.http.API_AUTH.delete(`cart/promocode/delete`, {
      data: dataQS,
    });
    if (response.status === 200) {
      const { data } = response;
      return data;
    } else {
      throw new Error(`Unexpected status code: ${response.status}`);
    }
  }
}
